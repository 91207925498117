body{
  font-family: 'Poppins', sans-serif;
  background-color: #f8fdff;
  padding-top:62px;
  padding-bottom: 80px;
}


.title{
  font-weight: bold;
  color: #837d7d;
}

a{
  text-decoration: none;
}

.transition{
  margin-left: -100vw;
  animation-duration: 0.3s;
  animation-name: slideOutLeft;
}

@keyframes slideOutLeft {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: -100vw;
  }
}

.transition-back-home.container{
  position: fixed;
  animation-duration: 0.3s;
  animation-name: slideOutRight;
}

@keyframes slideOutRight {
  0% {
    margin-left: 0;
  }
  100% {
    margin-left: 100vw;
    display: none;
  }
}