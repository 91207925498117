.keypass{
    height: 45px;
}

.keypass li{
    background-color: #711535;
    margin: 0px 4px;
    border-radius: 12px;
    padding: 0 5px;
    width: 16px;
    height: 16px;
    display: inline-block;
    font-size: 1px;
}

.touch-number{
    text-align: center;
    cursor: pointer;
}

.touch-number > span {
    padding: 30px 0;
    font-weight: bold;
    font-size: 20px;
    border: 1px solid #8f8856;
    color: #711535;
}

.touch-number > span:hover{
    background-color: #711535;
    color: #fff;
}
