#root .navbar{
    position: fixed;
    top:0;
    background-color: #1c1c1c;
    color: #fff;
    width: 100%;
    z-index: 1;
}

#root .navbar a{
    color: #fff;
}

#root .qr-code{
    position: absolute;
    top: 15px;
    left: 25px;
}

#root .logout{
    position: absolute;
    top: 15px;
    right: 25px;
}