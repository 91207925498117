.nav{
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border-top:1px solid #ccc;
    font-size:0.8rem;
    line-height: 15px;
}

.nav-item{
    padding:8px 16px;
    color: #837d7d;
}

.nav a{
    color: #837d7d;
}

.nav svg{
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 6px;
    font-size: 18px;
}

.nav-pills .nav-link{
    border-radius: 0;
}

.nav-item.active,
.active.nav-item{
    background-color: #ddcf5f;
}

.active a{
    color:#fff;
}