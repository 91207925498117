.bl{
    font-weight: bold;
    color:#837d7d;
    margin-top:-2px
}

.cde{
    font-size: 0.8rem;
    color:#b3b3b3;
}

.alert > small{
    line-height: 17px;
    display: block;
}

.parcel{
    display: block;
    font-size: 1.2rem;
    font-weight: bold;
    color: #ffaa64;
    margin-top: -12px;
}

.back-home{
    position: fixed;
    top: 12px;
    z-index: 10;
    left: 10px;
}

.carousel-indicators{
    display: none !important;
}